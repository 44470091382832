.selected-client-info {
	@include clearfix;
	margin-top: 0.25rem;
	margin-right: 0.25rem;
	&__cpg-balance {
		margin-right: 0.25rem;
	}
	&__client {
		&_name {
			float: right;
			margin-top: 0.25rem;
			padding-right: 0.625rem;	
			&:hover {
				cursor:pointer;
			}
			.login__popover_user-id-arrow {
				margin-top: 0.25rem;
			}
			.popover-content {
				padding: 0px;
			}
		}
	}
	&__balance {
		&_value {
			font-family: $font-family-number;
		}
		&_refresh-icon{
			&:hover{
				cursor:pointer;
			}
		}
	}
}

