.checkbox-component {
	font-size: 1.25rem;
	cursor: pointer;
	
	input[type=checkbox] {
		display:none;
	}
	label{
		margin-bottom: 0;
	}
	
	 &__disabled {
		cursor: not-allowed;
	}
}