.remote-filter-pagination{
	    display: flex;
    padding-left: 0;
    border-radius: 4px;
    width: 45%;
	float: right;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
	justify-content: flex-end;
    margin-right: 0.5rem;
	&__pagination{
		       float: right;
    display: contents;
		&_first, &_prev,&_last,&_next, &_page-count{
    float: left;
    padding: 4px 10px;
    line-height: 1rem;
    color:  $brand-color-2;
    border: 1px solid $gray-lighting;
	padding-top: 6px;
		&:hover{
			cursor: pointer;
		}
		}
		&_page-count{	
    		padding: 1px 10px;
    		width: 25%;
			text-align: center;
			    padding-top: 4px;
			.select-page{
				text-align: right;
				width:	50%;
			}
		}
	}
	&__filter{
		float: left;
		margin-left: 1rem;
		&_button{
			    background-color: $brand-color-2;
    			color: $light;
    			font-size: 10px;
				border	: none;
    line-height: inherit;
    padding: 0.5rem;
    text-align: center;
    text-transform: uppercase;			
		}
	}
	
}
.traveler-remote-filter{
	border: 5px solid $brand-color-3;
    padding: 1rem;
    width: 100%;
    display: flex;
	justify-content: space-between;
	&__form{
		display: flex;
    height: 50px;
    align-items: flex-start;
    align-content: flex-end;
    flex-direction: row;
	&_first-name, &_last-name, &_email, &_phone-no{
		width: 20%;
		.text-label{
			color: $brand-color-2 !important;
    font-size: .875rem;
    margin-bottom: 0;
    width: 100%;	
	text-transform: uppercase;
	    font-size: 12px;
	font-family: $font-family-sans-serif;
		} 
		.text-input{
			@include make-input;
			width:80%;
			input[type="text"]{
				height: 24px;
				padding: 2px 1px;
				border-radius: 3px;
			}
		}
	}
		&_submit-button,&_reset-button 
		{
			    margin-top: 16px;
			button{
			    background-color: $brand-color-1;
    			border: 1px solid $separator-color;
    			color: $light;
    			margin-right: 0.125rem;
    			padding: 0.2rem 0.1rem;
    			width: 8rem;
				border: none;
				text-transform: uppercase;
			}
		}
		&_reset-button{
			button{
				background-color: $brand-color-2;
			}
		}
	}
}