.quotation-acceptance-wrapper {
	@include clearfix(); 
	 height: 100%;
	 width: 100%;
	 background: $light;
}

.rfq-quotation-acceptance {
    height: 100%;
    overflow-y: scroll;
    &__container_description {
	    padding-top: 2.5rem;
	    
	    .description-note {
	    	position: relative;
    		left: 5%;
	    }
	}
    
    &__header {
	    content:"";
	    display:table;
	    border-bottom: 1px solid $brand-color-2;
	    width: 100%;
	    padding-top: 0.5rem;
    	padding-bottom: 1rem;
		
		&_logo {
		    float: left;
			width: 30%;
			height: 3rem;
				    
			.title-logo {
				content: "";
				display: table;
				position: relative;
				left: 4rem;
				height: 100%;
   				width: 8rem;
				
				img {
					height: 100%;
   					width: 100%;	
				} 
			}
		}
		
		&_title {
		    float: right;
		    font-size: $font-size-h2;
		    color: $brand-color-2;
		    text-transform: uppercase;
		    width: 70%;
		    text-align: right;
		    position: relative;
    		top: 1rem;
		    
		    .title_description {
			    position: relative;
			    right: 5%;
			}
		}
	}
	
	&__container {
		&_acceptance-form {
			@include travel-card;
			width: 90%;
		    margin: 5%;
		    margin-top: 2rem;
		    background: $light;
		    padding: 0;
		    
		    .acceptance-form-header {
				 width: 100%;
				 padding-top: 0.25rem;
				 padding-bottom: 0.5rem;
				 background-image: linear-gradient(75deg, $brand-color-2 20%, $brand-color-4 90%);
				    
			     &_title {
					  color: $light;
					  content:"";
					  display:table;
					  text-transform:uppercase;
					  padding-top:0.3750rem;
					  font-size: $font-size-h4;
					  font-family: $font-family-sans-serif;
					  text-align:center;
					  position: relative;
   					  left: 3rem;
			      }
			}
			
			.acceptance-form-product {
				padding: 0.65rem;
				min-height: 100px;
				padding-top: 1.25rem;
    			padding-bottom: 0.75rem;
				
				&_row {
				   width:100%;
				   
				   .row-checkbox,.row-details {
				   		content:"";
					  	display:table;
					  	float: left;
				   }
				   .row-checkbox {
					  width:5%;
					  height:100%;
					  text-align:center;
					  font-size: 1rem;
					  color:$brand-color-5;
					  padding-top: 2.25rem;
					}
					
					.row-details {
					  @include travel-card;
					  width:95%;
					  margin-bottom: 0.75rem;
					  margin-top: 0.25rem;
					  content: "";
					  display: table;
					  padding: 0;
					}
				}
				
				&_btn-container {
					content:"";
					display:table;
					width:100%;
					padding-top: 0.5rem;
					
					.btn-accept, .cancel-btn {
					    padding: 0.35rem;
					    padding-left: 1rem;
					    padding-right: 1rem;
					    background:$brand-color-3;
					    color:$brand-color-2;
					    float: right;
					}
					.btn-accept {
					    background: $brand-color-1;
					    color: $light;
					    width: 25%;
					}
					.btn-accept-disabled {
					    cursor: not-allowed;
					   	opacity: .65;
    				    box-shadow: none;
					}
					
					.btn-accept:hover {
						color: $light;
					    text-decoration: none;
					    background-color: $action-btn-hover;
					}
				}
				&_terms-and-condition-container {
					width: 100%;
					padding: .95rem 0 0 1.25rem;
					.terms-and-condition {
					    position: relative;
					    left: 1.35rem;
					    
					    .terms {
					    	&__checkbox {
					    		cursor: pointer;
					    	}
					    }
					}
				}
			}  
		}
	}
}

.quotation-header {
	background: $brand-color-2;
}




