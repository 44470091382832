@mixin create-flight-row_private {
  &_flight {
    @include make-one-fifth;
        // background-color: pink;
  }
  &_from,
  &_to {
    @include make-two-fifth;
  }
  &_duration {
    @include make-one-fifth;
  }
}

@mixin create-flight-detail-row_private {
  &__flight,
  &__from,
  &__to,
  &__duration,
  &__price {
    padding-top: $base-padding*0.5;
  }
  &__fare-basis {
  	color:$brand-color-2;
  }
  &__fare-rules, &__fare-basis {
  	font-size: $font-size-h6;
  }
  &__flight,
  &__duration {
    text-align: center;
  }
  &__from {
    text-align: left;
  }
  &__to {
    text-align: right;
  }
  &__from,
  &__to {
    &_airport, &_terminal, &_baggage, &_aircarft-type {
      font-size: $font-size-h6;
      word-wrap: break-word;
    }
    &_airline {
      font-size: $font-size-h6;
    }
    &_location {
      text-transform: uppercase;

      // font-size: $font-size-h4;
      color: $brand-color-2;
    }
    &_aircraft {
      text-transform: uppercase;
      color:$brand-color-2;
    }
    &_time,
    &_date {
      font-family: $font-family-number;

      // font-weight: 400;
    }
    &_date {
      font-size: .875rem;
      color: $brand-color-5;
    }
    &_time {
      font-size: $font-size-number-small;
      color: $brand-color-2;
      line-height: 1.125rem;
	  	.to-iata, .from-iata {
			width:100%;
		}
    }
    &_suitcase {
    	color: $brand-color-2;
    	font-size: 1.2rem;
    }
  }
  &__fid{
  	 font-family: $font-family-number;
  }
  &__airline-xid {
  	 font-family: $font-family-number;
  }
  &__duration {
   	&_flight-duration{
 	    font-size: 0.5625rem;
   	}
 	.flexi-time{
 		font-size: 0.75rem;
 		color: $gray;
 		&:before,
       	&:after {
 		    width: 1.0625rem;
 		    border: 0.0625rem lighten($brand-color-5, 20) dashed;
 		    background: $light;
       	}
 	}
  
    &_time {
      font-size: $font-size-number-small;
      font-family: $font-family-number;
      font-weight: 400;
      line-height: 1.125rem;
      color: lighten($brand-color-1, 20);
      position: relative;
      text-transform: lowercase;
      &:before,
      &:after {
        width: 60px;
        content: "";
        height: 1px;
        background-color: lighten($brand-color-5, 30);
        position: absolute;
        top: 50%;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
    &_wrapper {
      @include clearfix;
      @include make-four-fifth;
      padding-left: 0;
      padding-right: 0;
    }
  	&_duration-block{
       	padding-top: 1.25rem;
       	padding-bottom: 1.25rem;
       	padding-left: 1.85rem;
       }
  	}
  &__seat_count_red{
  	color: $brand-color-1;
  	font-size: 0.75rem;
  	font-weight: bold;
	font-family: $font-family-number;
  }
  &__seat_count_green{
  	color: $brand-color-18;
  	font-size: 0.75rem;
  	font-weight: bold;
	font-family: $font-family-number;
  }
  &__seat_text{
	 	color: $brand-color-2;
	 	text-transform: capitalize;
	 }
}

@mixin  create-flight-row { 
	@include create-flight-row_private; 
}
@mixin  create-flight-detail-row { 
	@include create-flight-detail-row_private; 
}