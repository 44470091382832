.product-notification {
	margin: 0.75rem 0;
  	&__summary {
    	display: flex;
	    padding: 1rem;
	    background: darken($light, 10%);
	    border-left: 5px solid $gray;
	    justify-content: space-between;
	    &_message {
			color: $gray;
			font-weight: bold;
		}
	    &_expander {
	      border-radius: 50%;
	      background-color: $gray-lighting;
	      width: 25px;
	      height: 25px;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      cursor: pointer;
	      font-weight: bold;
	    }
  	}
  	&__container {
	    &_notification {
          	display: flex;
		    padding: 1rem;
		    padding-bottom: 0;
		    background: darken($light, 2%);
		    justify-content: space-between;
		    flex-direction: column;
		    .alert__message {
				margin-bottom: 0.25rem;
			}
			&:last-child {
				padding-bottom: 1rem;
			}
	    }
	    .notification {
			&__type {
				font-weight: bold;
			}
	      	&__type-error {
				color: $brand-color-1;
			}
	      	&__type-warning {
				color: $brand-color-13;
			}
	      	&__type-info {
				color: $brand-color-2;
			}
	    }
  	}
}
