.radio-component {
	font-size: 1.25rem;
	
	input[type=radio] {
		display:none;
	}
	
	input[type="radio"] + label {
		cursor: pointer;
	}
	
	input[type="radio"]:checked + label {
		color: $brand-color-2;
		&::before {
			border: 2px solid $brand-color-2;
			background: $brand-color-2;
			box-shadow: inset 0 0 0 3px $light;
		}
	}
	input[type="radio"] + label::before {
		display: inline-block;
		content: "";
		height: 1.125rem;
		width: 1.125rem;
		margin-right: 0.625rem;
		border: 2px solid $gray-lighting;
		border-radius: 50%;
		transition: all 0.25s linear;
	}
	
}