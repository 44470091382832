@use "sass:math";

.action-modal{
	max-width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    font-size: 0.75rem;
}


	.action-modal-container{
	width:300px;
	margin:0 auto;
	padding:1rem;
	&__message{
		 color: $brand-color-2;
		 padding-bottom: math.div($base-padding, 3);
		 margin-left: -20%;
		 margin-right: -20%;
	     text-align:center;
	     font-size:1.2rem;
    }
	&__action-button-container{
		@include clearfix();
		width:100%;
		&_approve-button,&_reject-button{
		   @include button-size(math.div($base-padding, 6), 0, 10px, inherit, 0);
           @include make-button($brand-color-2, $brand-color-3, $dark, darken($brand-color-3, 20));
           font-weight: 700;
	       text-align: center;
	       text-transform: uppercase;
		   width: 40%;
		   float: left;
		   margin-left: 10%;
		   cursor: pointer;
		}
	}
}