.persisted-dynamic-form{
	 &__content{
		 @include clearfix;
	 }
	 &__link{
	     padding-top: .3125rem; 
	     padding-bottom: .3125rem; 
         cursor: pointer;
	     text-align: left; 
	     color: $brand-color-2;
	
	 }
	 &__dynamic-form{
		@include clearfix;
		width:100%
	 }
	 &__header{
	    @include clearfix;
	  	width: 100%;
		background-color: $brand-color-4; 
	     &_title {
            line-height: $modal-title-line-height;
			padding-top: .3125rem;
			padding-bottom: .3125rem;
			display: block;
			text-align: center;
			color: $brand-color-2;
			font-size:20px;
			margin: 0;
			width:95%;
			float: left;
		}
		&_close-icon{
			cursor: pointer;
			float: right;
		}
	 }
 
    &__heading-container { 
        @include clearfix;
		 display: inline-table;
    	 width: 100%;
    	 border-bottom: 1px solid $brand-color-3;
    
	    &_heading,&_close-button { 
				font-size: 1.25rem;
				padding-bottom: 0.5rem;
				margin-bottom: 0.5rem;
		}
			
		&_heading {
				width: 90%;
				color: $brand-color-2;
				text-align: center;
				float: left;
		}
			
		&_close-button {
				text-align: right;
				cursor: pointer;
				float: right;
		}
	}
	&__summary{
	    padding: .725rem;
	    border: 10px solid $brand-color-3;
	    margin-bottom: .725rem;
	    font-family: $font-family-number;
	}
	
	&__content-disable {
		cursor: not-allowed;
	}
}