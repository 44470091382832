.airline-selector {
	&__wrapper {
	    padding-left: .9375rem;
		padding-right: .9375rem;
		width : 50%;
		float : left;
		.multi-select{
		    margin-top: 0.25rem;
			.dynamic-multi-select__label{
				margin-bottom: 0.25rem;
		    	color: $brand-color-2;
		    	font-size: 0.875rem;	
			}
			.glyphicon-ok {
				width: 8%;
			    float: right;
			    font-size: 1rem;
			}			
			.multi-airline-select {
   				@include clearfix;
				width: 92%;
				&__name {
					float: left;
					width: 88%;
				}
				&__image {
					float: left;
				    width: 12%;
    				margin-top: -7px;
    				img {
    				    border-radius: 50%;
    				    border: 1px solid;
    				    width: 80%;
    				}
				}
			}
		}
	}
}