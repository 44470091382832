@mixin travel-card_private{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  //height:200px;
  //width:230px;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;

&__header{
  	padding-bottom: 0.4375rem;
    border-bottom: 1px solid lighten($brand-color-5, 20);
    color:$brand-color-2;
  	font-weight: 600;
  	font-size:$font-size-h5;
  	text-transform:uppercase;
}

     &__product-header {
      @include clearfix;
      background-color: $body-bg;
      padding-left: 0.725rem;
      padding-right: 0.725rem;
      margin-top: -6px;
      padding-top: .375rem;
      padding-bottom: 0.375rem;
	  border-top: 1px solid  $brand-color-2;
	  border-bottom: 1px solid  $brand-color-2 !important;
      &_index {
        float:left;
        width: 50%;
        text-align: left;
        color: $brand-color-2;
      }

      &_product-type {
        float:left;
        width: 50%;
        text-align: right;
        text-transform: uppercase;
        color: $brand-color-2;
      }
}

&__content{

  }


}

@mixin  travel-card{ 
	@include travel-card_private; 
}