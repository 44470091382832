$row-bgcolor:                        $light !default;
$row-shadow-color:                  0 0 15px rgba(0, 0, 0, .25) !default;

$row-footer-bgcolor:                $brand-color-4 !default;

$row-flight-border-color:           lighten($brand-color-2, 50) !default;
$row-price-border-color:            lighten($brand-color-2, 50) !default;

$row-time-color:                    $brand-color-2 !default;

$row-duration-color:                lighten($brand-color-1, 20) !default;

$row-flight-details-border-color:   lighten($brand-color-2, 50) !default;

$row-flight-details-button-bgcolor: $brand-color-3 !default;
$row-flight-details-button-state:   $dark !default;

$row-book-border-color:             lighten($brand-color-2, 50) !default;
$row-book-button-bgcolor:           $brand-color-1 !default;
$row-book-button-state:             $brand-color-1 !default;
$card-shadow-color:                  0 0 15px rgba(0, 0, 0, .50) !default;


