.email-result__page{
    &__header{
	   @include clearfix;
       &_text{
      		color: $brand-color-5;
      		padding-left: 0px;
      		font-size:12px;
      		float:left;
       } 	
    }
  	&__control-buttons {
		@include clearfix;
		width: 100%;
		margin: 0 auto;
		.control-buttons {
				&__send-email{
					@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
	    			margin-top: 1.525rem;
					padding-top: .3125rem;
					padding-bottom: .3125rem;
					font-weight: 700;
					width: 100%;
				}
		}
	}	    
    label {
	    margin-top: 10px;
	    color: $sf-label-color;
	    font-size: 0.875rem;
	    width:100%;
  	}
	input[type="email"], input[type="text"] {
	    width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    background-color: $input-bgcolor;
	    border: 1px solid;
	    border-color: $input-border-color;
	    color: $text-color;
	    max-height: 34px;
		&:disabled {
	   		background-color: $gray-lightest;
		}
	}
    textarea {
	    width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    background-color: $input-bgcolor;
	    border: 1px solid;
	    border-color: $input-border-color;
	    height: 70px;
	    color: $text-color;
  	}
  	&__alert_message{
	   @include clearfix;
	   width: 100%;
	}
  	.alert__message--error, .alert__message--success {
  		margin-top: 2rem;
  		width:100%;
  	} 	
	.form-field-validation-error {
		margin-bottom: 0;
		font-size: 0.8rem;
	}
}
.email-result-modal {
	.modal-dialog {
	  	 width: 40%;
	     padding-top: 5.525rem;
	} 
}
.card__send-email{
.email-result__page {
	&__alert_message{
	   @include clearfix;
		display: table;	   
	   width: 100%;
	}
    &__control-buttons {

        width: 13rem;
        float: right;
        padding-bottom: 1rem;
        padding-right: 2rem;

        button {
            @include button-size(6px, 12px, 14px, 20px, 4px);
            @include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
        }
    }

        &__control-buttons-container {
            display: table;
        width: 100%;
    }

    }

.email-results_form {
    display: table;
    width: 100%;

    &__fields {
        display: table;
        width: 100%;
        padding-top: 1rem;
            .form-field-validation-error {
                margin-bottom: 0;
                font-size: 0.8rem;
                color:$brand-color-1;
                
                .display-error-message {
					position: relative;
					left: 5%;
				}	            
    		}
        }
    .email-field-check{
		@include clearfix();
		padding-left: 2.5rem;
		&_input-checkbox{
			float:left;
			padding: 0 0.25rem;
		}
		&_label{
			float:left;
			padding-left: 0.25rem;
    		line-height: 1.75rem;
		}
	}
}

.fields_label {
    float: left;
    display: table;
    width: 5%;
}

.fields_input {
    display: table;
	width: 50%;
	.ui-select-multiple{
		height: 3rem;
	}
}

}