.cookie-consent {
    padding: 0.5rem;
    width: 100%;
    margin: auto;
    position: fixed;
    bottom: 0;
    z-index: 1100;	
    background:$dark;
    
    &__text {
    	width: 50%;
    	float: left;
   	    color: $light;
   	 	font-size: 1rem;
    	font-weight: 500;
    	margin-left: 2rem;
    }
    &__action {
    	width: 45%;
    	float: left;
    	&_accept {
    		float: right;
    		padding: 0.5rem 2rem 0.5rem 2rem;
    		margin-right: 2rem;
    		background-color: $brand-color-2;
    		color: $light;
    		font-weight: 700;
   		    text-transform: uppercase;
    	}
    }
	
}