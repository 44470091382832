multi-image-carousel-display {
	display: block;
}

.multi-image-carousel {
	
	position: relative;
	margin: auto;
	padding-right: 0.95rem;
  	height: 110px;
	overflow: hidden;
	@include make-responsive-width('max-width',$sm-screen-size,$md-screen-size,$lg-screen-size);
	
	&__prev-btn, &__next-btn {
		height: 100%;
    	padding: 10px;
    	position: absolute;
    	top: 0;
    	width: 2rem;
     	cursor: pointer;
    	.fa {
	    	color: $brand-color-24;
     		font-size: 2.35rem;
		    position: absolute;
		    top: 35%;
     	}
	}
	
	&__prev-btn {
    	left: 0;
    	.fa {
		    left: 0.25rem;
     	}
	}
	&__next-btn {
    	right: 0;
    	.fa {
		    right: 0.25rem;
     	}
	}
	.multi-image-carousel-container {
		
	    margin-left: 0.8rem;
		overflow: hidden;
		max-width: 100%;
		width: 100%;
		&__content {
		   	 height: 200px;
		     transition: all ease-in-out 0.5s; 
		}
		&__slide {
				height: 150px;
				float:left;
		}
		
	}
	
	.multi-slide-carousel-slide-container {
			max-height: 135px; 
    		height: 100%;
		 	padding: 1rem 1rem 1.8rem 1rem;
		 	text-align: center;
			&__slide {
				width: 150px;
				height: 100%;
				border: 2px solid $light;
			    opacity: 0.5;
			} 
			&__slide:hover{
			    opacity: 1;
			}
	}
	.active {

		&__img {
			.multi-slide-carousel-slide-container {
				&__slide {
					opacity: 1;
					border-color: $brand-color-2;
				}
			}
		}
	}
}

.multi-image-carousel-vertical {
	width: 100%;
	@include clearfix();

	&__prev-btn,
	&__next-btn {
		width: 100%;
		text-align: center;
		cursor: pointer;
		.fa {
			color: $brand-color-24;
			font-size: 2.35rem;
		}
	}
}
.multi-image-carousel-vertical-container {
	overflow: hidden;
	max-width: 100%;
	width: 100%;

	&__content {
		height: calc(100vh - 225px);
		transition: all ease-in-out 0.5s;
		width: 100%;
	}

	&__slide {
		width: 70%;
		margin: 0 auto;
	}
}

.vertical-multi-slide-carousel-slide-container {
	width: 100%;
	@include clearfix();
	&__slide {
		opacity: .5;
		border-color: $brand-color-2;
		height: 130px;
		width: 100%;
		padding: 10px;
		margin: 0 auto;
		&-active{
			opacity: 1;
			border: 1px solid $brand-color-24;
		}
	}
}