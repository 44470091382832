.product-details {
 	@include clearfix;
 	width: 100%;
 	padding-bottom: 0.5rem;
 	
 	&__action-buttons {
 		margin-top: 0.5rem;
 	}
 	
 	&__product-container {
 		@include clearfix;
 		width: 100%;
 		font-size: 1rem;
 		border-bottom: 1px solid $brand-color-3;
 		color: $gray;
 		min-height: 90px;
 	}
 	&__product-container-header {
 	    width: 100%;
	    display: table;
	    content: "";
	    padding: 0.65rem;
	    min-height: 50px;
	    
	    
 		&_content-tab {
 			float: left;
 			margin-top: 0.375rem;
 			padding-left: 0.25rem;
    		padding-right: 0.5rem;
    		width: 30%;
    		
		    .elipses-active {
	    		@include text-overflow;
	    	}
    		.tooltip-product-desc {
    			.tooltip-inner {
					background: $light;
	    			color: $gray;
	    			box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
	    			padding-top: 0.75rem;
				    padding-left: 2.5rem;
				    padding-right: 2.5rem;
				    padding-bottom: 0.75rem;
				    min-width: 390px;
				    font-size: $font-size-h4;
				    font-weight: bold;
				    text-align: left;
    			}
    			.tooltip-arrow {
    				border-top-color: $light;
    			}
    		}
 		}
 		&_icon {
 			float: left;
 			font-size: 1.25rem;
 			width: 5%;
 		}
 		&_booking-status {
 			width: 10%;
 			font-family: $font-family-number;
 			float: left;
 			width: 15%;
 			margin-top: 0.375rem;
 		}
 		&_price-details {
 			width: 15%;
   			padding: 0rem;
 			text-align: center;
 			margin-top: 0rem;
 			font-family: $font-family-number;
 			width: 20%;
 			
 			.price-details{
 				&__amount{
					color: $brand-color-1;
					font-size: 1.25rem;
				}
				&__currency{
					font-size: .875rem;
	    			margin-top: .3rem;
				}
 			}
 		}
 	}
 	
 	&__product-container-footer {
 		content: "";
 		display: table;
	    min-height: 40px;
	    width: 100%;
	    background: #e9f1f6;
	    &_extras{
	    	float: right;
   			margin: 0;
    		font-size: 1.75rem;
			padding: 0 1rem;
	    }
 	}
 }
.quotation-acceptance-remark{
	&__description{
		padding:0.5rem;
	}
}



