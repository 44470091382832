.daily-cash-limit {
	@include clearfix;
	display: flex;
	&__balance {
		display: flex;
		&_air-limit {
			margin-right: 0.25rem;
		}
		&_air-limit,
		&_non-air-limit {
			.air-limit,
			.non-air-limit {
				&__value {
					font-family: $font-family-number;
				}
			}
		}
	}
	
	&__refresh-icon {
		margin-left: 0.25rem;
		&:hover{
			cursor:pointer;
		}
	}
}

