.important-notice{
	&__title{
		color: $brand-color-2;
	    font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 0.0625rem solid $brand-color-3;
	    padding-bottom: 0.5rem;
	}
	&__user{
		border-top:0.0625rem solid darken($brand-color-4,20);
		&_name,&_notice{
			margin-left: 1rem;
		}
		&_name{
			color:$brand-color-2;
			margin-top: 0.5rem;
		}
		&_notice{
			margin-bottom: 0.5rem;
		}
	}
	&__close-button{
		float: right;
    	cursor: pointer;
    }
}