.tax-breakup {

  &_card {
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      }
    .header_text {
      margin-right: $base-margin;
    }

  }
  &__table {
  
    padding-left: 5px;
    padding-right: 5px;
    >table {
      width: 100%;
      font-family: $font-family-number;
      >thead {
        text-transform: uppercase;
        background-color: $brand-color-4;
        th:first-child {
          padding-left: 10px;
          padding-right: 5px;
          padding-top: 5px;
          text-align: left;

        }
        th {
          padding: 5px;
          text-align: right;
        }
      }
      >tbody {
        margin-top: 15px;
        >tr {
          border-bottom: 2px solid $brand-color-4;
          td {
            padding: 5px;
            text-align: right;
            color: $brand-color-2;
            text-transform: none;
          }
          td:first-child {
            padding-left: 10px;
            padding-right: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: left;
            color: $brand-color-5;
          }
          &:nth-child(2) {
            td {
            
              text-transform: none;
            }
          }
        }
        tr:last-child {
          background-color: $brand-color-4;
          td {
            padding: 5px;
            text-align: right;
            color: $dark;
          }
          td:first-child {
            padding-left: 10px;
            padding-right: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: left;
            text-transform: none;
          }
        }
      }
    }
  }
}

.review-fare-details {
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0px;
    
    }
    .header_text {
      margin-right: $base-margin;
    }

  }
  &__table {
  	&_price-breakup{
  		float: left;
  		text-transform: uppercase;
  		padding: 0.375rem 0;
  	} 
  	&_price-adhoc-amount{
  		padding: 0.375rem 0;
  		float: right;
  	}
    table {
      width: 100%;
      font-family: $font-family-number;
      thead {
        text-transform: uppercase;
        background-color: $brand-color-4;
        th:first-child {
          padding-left: 10px;
          padding-right: 5px;
          padding-top: 5px;
          text-align: left;

        }
        th {
          padding: 5px;
          text-align: right;
        }
      }
      tbody {
        tr {
          border-bottom: 2px solid $brand-color-4;
          td {
            padding: 5px;
            text-align: right;
            color: $brand-color-2;
            &:first-child {
              padding-left: 10px;
              padding-right: 5px;
              padding-top: 5px;
              padding-bottom: 5px;
              text-align: left;
              color: $brand-color-5;
            }
          }
        }
      }
    }
  }

}

.total-amount,
.grand-total-amount {
	padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    text-transform: uppercase;
    &__value,
    &__result-page-value {
    	float: right;
    	color: $brand-color-2;
    }
    &__result-page-value {
    	padding-right : 1.7rem;
    	color: inherit;
    }
}

.grand-total-amount {
	background-color: $brand-color-4;
	color: $dark;
	&__value,
    &__result-page-value {
    	color: $dark;
    }
}

