.phone-number-component{
	line-height: normal;
	&__country-code{
			padding-right:0.25rem;
			width: 30%;
			float: left;
	}

		.phone-num-with-country-code {
     		width: 70%;
     		float: right;
    	 }
		.phone-num-without-country-code {
     		width: 100%;
		 }
		 
		 .phone-number-input{
			 width: 100%;
			 padding: 0.5rem 1rem;
			 max-height: 34px;
		 }
	
}


	