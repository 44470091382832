.toggle-display-container {
    border: 1px solid $gray-lighting;
    border-radius: 0.25rem;
    padding: .25rem .5rem;
    width: 100%;
    
    &__header {
		color: $brand-color-2;

	   &_close {
	   		cursor: pointer;
  			float: right;
	   }
    }
}